.homeBanner {
  height: 80vh;
  background-image: linear-gradient(to right bottom, #7ac144d3, #4e7c2bc0),
    url("../../assets/images/welcomeBanner.jpg");
  background-size: cover;
  background-position: center;
}

.officeBanner {
  height: max-content;
  background-image: linear-gradient(to right bottom, #282f6bef, #282f6bd3),
    url("../../assets/Logo/office.jpg");
  background-attachment: fixed;
}

@keyframes animateLogo {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }

  60% {
    opacity: 0.6;
    transform: translateY(-20px);
  }

  80% {
    opacity: 0.8;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
  }
}

.logoAnimation {
  animation: animateLogo ease-in 1s;
}
