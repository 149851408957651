.success {
  height: max-content;
  width: 300px;
  background-image: linear-gradient(
      to right bottom,
      rgb(12, 73, 17),
      rgba(41, 139, 41, 0.918)
    ),
    url("../../assets/images/img6.jpg");
  background-position: center;
}
.error {
  height: max-content;
  width: 300px;
  background-image: linear-gradient(
      to right bottom,
      rgba(168, 6, 6, 0.876),
      rgba(149, 0, 0, 0.904)
    ),
    url("../../assets/images/img6.jpg");
  background-position: center;
}
.warning {
  height: max-content;
  width: 300px;
  background-image: linear-gradient(
      to right bottom,
      rgba(255, 255, 0, 0.698),
      rgba(255, 255, 0, 0.904)
    ),
    url("../../assets/images/img6.jpg");
  background-position: center;
}
