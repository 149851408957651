.contactBg {
  background-image: url("../../assets/images/about.jpg");
  background-position: center;
  background-size: cover;
  height: 60vh;
}
.contactBgMobile {
  background-image: linear-gradient(
      to right bottom,
      rgba(0, 0, 0, 0.597),
      rgba(0, 0, 0, 0.608)
    ),
    url("../../assets/images/about.jpg");
  background-position: center;
  background-size: cover;
  height: max-content;
}
.pictureBackground {
  width: 100%;

  background-image: linear-gradient(
    to right bottom,
    rgba(0, 0, 0, 0.447),
    rgba(0, 0, 0, 0.418)
  );
}

.contactText {
  animation: fadeInAnimation ease-in 1s;

  /* animation-fill-mode: forwards; */
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
    transform: translateX(40px);
  }

  60% {
    opacity: 0.6;
    transform: translateX(-20px);
  }
  80% {
    opacity: 0.8;
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
  }
}
