.footerBg {
  background-image: linear-gradient(
      to right bottom,
      rgba(4, 29, 4, 0.712),
      rgba(41, 105, 41, 0.692)
    ),
    url("../assets/images/img5.jpg");
  background-position: center;
  background-size: cover;
}
