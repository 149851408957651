.imgName {
  background-image: linear-gradient(
      to right bottom,
      rgba(0, 102, 255, 0.651),
      rgba(4, 65, 116, 0.699)
    ),
    url("../../assets/images/architecture-1048092_1920.jpg");
  background-position: center;
  background-size: cover;
}
.imgName2 {
  background-image: linear-gradient(
      to right bottom,
      rgba(0, 0, 0, 0.521),
      rgba(128, 128, 128, 0.932)
    ),
    url("../../assets/images/coffee-2511065_1920.jpg");
  background-position: center;
  background-size: cover;
}
.imgName3 {
  background-image: linear-gradient(
      to right bottom,
      rgba(0, 0, 0, 0.521),
      rgba(128, 128, 128, 0.932)
    ),
    url("../../assets/images/pexels-fauxels-3184292.jpg");
  background-position: center;
  background-size: cover;
}
.imgName4 {
  background-image: linear-gradient(
      to right bottom,
      #a52a2aa4,
      rgba(165, 42, 42, 0.507)
    ),
    url("../../assets/images/pexels-geoffrey-werner-627924.jpg");
  background-position: center;
  background-size: cover;
}

.textDiv {
  height: 400px;
  overflow-y: scroll;
}

.textDiv2 {
  height: 300px;
  overflow-y: scroll;
}
